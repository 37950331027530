<template>
    <div class="ont">
        <div v-if="IsLoading" class="loading">Pobieranie...</div>
        <div v-if="Ont" class="content">
            <div class="card mt-4">
                <div class="card-header">
                    Info
                </div>
                <div class="card-body">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Olt</th>
                                <th>Port</th>
                                <th>Ont</th>
                                <th>Line Profile</th>
                                <th>Srv Profile</th>
                                <th>SN/MAC</th>
                                <th>Opis</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <router-link :to="`/huawei/olt/${Ont.OltId}`">{{ Ont.OltName }}</router-link>
                                </td>
                                <td>
                                    {{ Ont.Frame }}/{{ Ont.Slot }}/{{ Ont.Port }}
                                </td>
                                <td>{{ Ont.OntId }}</td>
                                <td>{{ Ont.LineProfile }}</td>
                                <td>{{ Ont.SrvProfile }}</td>
                                <td>{{ Ont.SerialOrMac }}</td>
                                <td>{{ Ont.Description }}</td>
                                <td v-if="Ont.Status">
                                    <button type="button" class="btn btn-success" disabled>Online</button>
                                </td>
                                <td v-else>
                                    <button type="button" class="btn btn-danger" disabled>Offline</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card mt-4">
                <div class="card-header">
                    Native VLAN
                    <div style="float: right">
                        <div @click="ShowNativePortModal">
                            <i class="fas fa-plus"></i>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>VLAN</th>
                                <th>Port</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="NativePort in NativePorts" v-bind:key="NativePort.Id">
                                <td>{{ NativePort.Vlan }}</td>
                                <td>
                                    <div v-for="Port in  NativePort.Port" v-bind:key="Port.Id">
                                         <div @click="DeleteNativePort(NativePort.Vlan, Port)">LAN{{ Port }} <i class="fas fa-trash"></i></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card mt-4">
                <div class="card-header">
                    Service Porty
                    <div style="float: right">
                        <div @click="ShowServicePortModal">
                            <i class="fas fa-plus"></i>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>VLAN</th>
                                <th>User VLAN</th>
                                <th v-if="!Ont.IsEpon">Gem</th>
                                <th>IPTV</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="ServicePort in ServicePorts" v-bind:key="ServicePort.Id">
                                <td>{{ ServicePort.Id }}</td>
                                <td>{{ ServicePort.Vlan }}</td>
                                <td>{{ ServicePort.UserVlan }}</td>
                                <td v-if="!Ont.IsEpon">{{ ServicePort.Gem }}</td>
                                <td v-if="ServicePort.Iptv">Tak</td>
                                <td v-else>Nie</td>
                                <td class="text-center" @click="DeleteServicePort(ServicePort.Id, Ont.OltId)">X</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-xl-12">
                    <div class="card mb-4">
                        <div class="card-header text-center">
                            <button type="button" class="btn btn-info me-4" @click="GetOntWanInfo">WAN</button>
                            <button type="button" class="btn btn-info me-4" @click="GetOntPortState">LAN</button>
                            <button type="button" class="btn btn-info me-4" @click="GetOntMacAddress">MAC</button>
                            <button type="button" class="btn btn-info me-4" @click="GetOntServicePorts">SERVICE PORTY</button>
                            <button type="button" class="btn btn-info me-4" @click="GetOntOpticalInfo">SIŁA SYGNAŁU</button>
                        </div>
                        <div class="card-body">
                            <div v-if="OutputIsLoading">Pobieranie...</div>
                            <div id="output" v-if="Output">
                                <div @click="ClearOutput"><i class="fas fa-times"></i></div>
                                <pre>{{ this.Output }}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4" v-if="ShowWan">
                <OntSetup :ontid="Ont.Id" :oltid="Ont.OltId" :serialormac="Ont.SerialOrMac" />
            </div>
        </div>
    </div>

<Modal v-show="IsModalServicePortVisible" @close="CloseServicePortModal" v-if="Ont">
    <template v-slot:body>
        <form @submit.prevent="AddServicePort">
            <div class="form-floating mb-3">
                <select class="form-select" id="vlan" aria-label="Vlan" v-model="ModalServicePortVlan" required>
                    <option v-for="tspdv in TemplateServicePortDataVlans" v-bind:key="tspdv.Id">{{ tspdv.VlanId }}</option>
                </select>
                <label for="vlan">Vlan</label>
            </div>
            <div class="mb-3">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="userVlanCheck" type="checkbox" name="userVlanCheck" v-model="ShowUserVlan" />
                    <label class="form-check-label" for="userVlanCheck">User Vlan</label>
                </div>
            </div>
            <div class="form-floating mb-3" v-if="ShowUserVlan">
                <input class="form-control" id="userVlan" type="text" placeholder="User VLAN" v-model="ModalServicePortUserVlan" required />
                <label for="userVlan">User VLAN</label>
            </div>
            <div class="form-floating mb-3" v-if="Ont.isEpon">
                <input class="form-control" id="gem" type="text" placeholder="GEM" v-model="ModalServicePortGem" required />
                <label for="gem">GEM</label>
            </div>
            <div class="mb-3">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="iptv" type="checkbox" name="iptv" v-bind:true-value="1" v-bind:false-value="0" v-model="ModalServicePortIptv" />
                    <label class="form-check-label" for="iptv">IPTV</label>
                </div>
            </div>
            <div class="d-grid">
                <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Zapisz</button>
            </div>
        </form>
    </template>
</Modal>

<Modal v-show="IsModalNativePortVisible" @close="CloseNativePortModal" v-if="Ont">
    <template v-slot:body>
        <form @submit.prevent="AddNativePort">
            <div class="form-floating mb-3">
                <select class="form-select" id="vlan" aria-label="Vlan" v-model="ModalNativePortVlan">
                    <option v-for="ServicePort in ServicePorts" v-bind:key="ServicePort.VlanId" v-bind:value="ServicePort.Vlan">{{ ServicePort.Vlan }}</option>
                </select>
                <label for="vlan">VLAN</label>
            </div>
            <div class="mb-3">
                <label class="form-label d-block">Ports</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="lan1" type="checkbox" name="ports" value="1" v-model="ModalNativePort" />
                    <label class="form-check-label" for="lan1">LAN1</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="lan2" type="checkbox" name="ports" value="2" v-model="ModalNativePort" />
                    <label class="form-check-label" for="lan2">LAN2</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="lan3" type="checkbox" name="ports" value="3" v-model="ModalNativePort" />
                    <label class="form-check-label" for="lan3">LAN3</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="lan4" type="checkbox" name="ports" value="4" v-model="ModalNativePort" />
                    <label class="form-check-label" for="lan4">LAN4</label>
                </div>
            </div>
            <div class="d-grid">
                <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Zapisz</button>
            </div>
        </form>
    </template>
</Modal>

</template>

<script>
import axios from 'axios'
import OntSetup from '../../components/OntSetup.vue'
import Modal from '../../components/Modal.vue'

export default {
    data() {
        return {
            Ont: null,
            NativePorts: null,
            ServicePorts: null,
            IsLoading: false,
            Output: null,
            OutputIsLoading: false,
            IsModalServicePortVisible: false,
            IsModalNativePortVisible: false,
            ShowUserVlan: false,
            ModalNativePort: [],
            TemplateServicePortDataVlans: null,
            ModalServicePortVlan: null,
            ModalServicePortUserVlan: null,
            ModalServicePortIptv: null,
            ModalNativePortVlan: null,
            ShowWan: true
        };
    },
    components: {
        OntSetup,
        Modal
    },
    created() {
        this.GetOntInfo()
    },
    //watch: {
    //    $route: "GetOltData",
    //},
    methods: {
        GetOltData()
        {
            this.Ont = null
            this.IsLoading = true

            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiGetOltData: {
                    DbOntId: parseInt(this.$route.params.id),
                },
            }

            axios.post("/huawei/ont/get/oltdata", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.GetOntInfo()
                this.IsLoading = false
            })
        },
        GetOntInfo()
        {
            this.Ont = null

            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOntGetInfo: {
                    Id: parseInt(this.$route.params.id),
                },
            }

            axios.post("/huawei/ont/get/info", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                if (result.data.Error)
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })

                this.Ont = result.data.HuaweiOntGetInfo
                this.ListNativePorts()
                this.ListServicePorts()
            })
        },
        ListNativePorts()
        {
            this.NativePorts = null

            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOntListNativePorts: {
                    OntId: parseInt(this.$route.params.id),
                },
            }

            axios.post("/huawei/ont/nativeport/list", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                if (this.isset(() => result.data.HuaweiOntListNativePorts))
                    this.NativePorts = result.data.HuaweiOntListNativePorts
                else
                    this.NativePorts = ""
            })
        },
        ListServicePorts()
        {
            this.ServicePorts = null
            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOntListServicePorts: {
                    OntId: this.Ont.Id
                },
            }

            axios.post("/huawei/ont/serviceport/list", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                if (this.isset(() => result.data.HuaweiOntListServicePorts))
                    this.ServicePorts = result.data.HuaweiOntListServicePorts
                else
                    this.ServicePorts = ""
            })
        },
        GetOntWanInfo() {
            this.Output = null
            this.OutputIsLoading = true

            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOntGetOntWanInfo: {
                    OntId: parseInt(this.$route.params.id),
                },
            }

            axios.post("/huawei/ont/get/ontwaninfo", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.Output = result.data.HuaweiOntGetOntWanInfo
                this.OutputIsLoading = false;
            })
        },
        GetOntPortState() {
            this.Output = null
            this.OutputIsLoading = true

            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOntGetOntPortState: {
                    OntId: parseInt(this.$route.params.id),
                },
            }

            axios.post("/huawei/ont/get/ontportstate", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.Output = result.data.HuaweiOntGetOntPortState
                this.OutputIsLoading = false
            })
        },
        GetOntMacAddress() {
            this.Output = null
            this.OutputIsLoading = true

            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOntGetOntMacAddress: {
                    OntId: parseInt(this.$route.params.id),
                },
            }

            axios.post("/huawei/ont/get/ontmacaddress", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.Output = result.data.HuaweiOntGetOntMacAddress
                this.OutputIsLoading = false
            })
        },
        GetOntServicePorts() {
            this.Output = null
            this.OutputIsLoading = true

            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOntGetOntServicePorts: {
                    OntId: parseInt(this.$route.params.id)
                },
            }

            axios.post("/huawei/ont/get/ontserviceports", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.Output = result.data.HuaweiOntGetOntServicePorts
                this.OutputIsLoading = false
            })
        },
        GetOntOpticalInfo() {
            this.Output = null
            this.OutputIsLoading = true

            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOntGetOntOpticalInfo: {
                    OntId: parseInt(this.$route.params.id),
                },
            }

            axios.post("/huawei/ont/get/ontopticalinfo", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.Output = result.data.HuaweiOntGetOntOpticalInfo
                this.OutputIsLoading = false
            })
        },
        ClearOutput() {
            this.Output = null
        },
        ShowServicePortModal()
        {
            this.GetTemplateServicePortDataVlans()
            this.IsModalServicePortVisible = true
        },
        CloseServicePortModal()
        {
            this.IsModalServicePortVisible = false
        },
        ShowNativePortModal()
        {
            this.IsModalNativePortVisible = true
        },
        CloseNativePortModal()
        {
            this.IsModalNativePortVisible = false
        },
        GetTemplateServicePortDataVlans()
        {
            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOltListTemplateServicePortDataVlans: {
                    OltId: this.Ont.OltId
                }
            }

            axios.post("/huawei/olt/template/serviceports/data/vlans/list", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.TemplateServicePortDataVlans = result.data.HuaweiOltListTemplateServicePortDataVlans
            });
        },
        AddServicePort()
        {
            const { ModalServicePortVlan, ModalServicePortUserVlan, ModalServicePortGem, ModalServicePortIptv } = this
            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOntAddServicePort: {
                    OntId: parseInt(this.$route.params.id),
                    Vlan: parseInt(ModalServicePortVlan),
                    UserVlan: ModalServicePortUserVlan ? parseInt(ModalServicePortUserVlan) : parseInt(ModalServicePortVlan),
                    Gem: ModalServicePortGem ? parseInt(ModalServicePortGem) : -1,
                    Iptv: ModalServicePortIptv ? parseInt(ModalServicePortIptv) : 0
                }
            }

            axios.post("/huawei/ont/serviceport/add", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.ListServicePorts()
                this.CloseServicePortModal()
            });
        },
        AddNativePort()
        {
            const { ModalNativePortVlan, ModalNativePort } = this
            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOntAddNativePort: {
                    DbOntId: this.Ont.Id,
                    OltId: this.Ont.OltId,
                    Frame: this.Ont.Frame,
                    Slot: this.Ont.Slot,
                    Port: this.Ont.Port,
                    OntId: this.Ont.OntId,
                    NativeVlan: parseInt(ModalNativePortVlan),
                    NativePorts: ModalNativePort
                }
            }

            axios.post("/huawei/ont/nativeport/add", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.ListNativePorts()
                this.CloseNativePortModal()
            });
        },
        DeleteServicePort(servicePortId, oltId)
        {
            var status = confirm("Czy skasować Service Port ?")
            if (status)
            {
                const data = {
                    SessionId: localStorage.getItem("user"),
                    HuaweiOntDeleteServicePort: {
                        OltId: oltId,
                        ServicePortId: servicePortId
                    }
                }

                axios.post("/huawei/ont/serviceport/delete", data).then((result) => {
                    if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                    {
                        this.$store.dispatch('alert/error', result.data.Error, { root: true })
                        this.$store.dispatch('auth/logout')
                    }

                    this.ListServicePorts()
                });
            }
        },
        DeleteNativePort(vlan, port)
        {
            var status = confirm("Czy skasować Native Port ?")
            if (status)
            {
                const data = {
                    SessionId: localStorage.getItem("user"),
                    HuaweiOntDeleteNativePort: {
                        DbOntId: this.Ont.Id,
                        OltId: this.Ont.OltId,
                        Frame: this.Ont.Frame,
                        Slot: this.Ont.Slot,
                        Port: this.Ont.Port,
                        OntId: this.Ont.OntId,
                        OntVlan: vlan,
                        OntPort: port
                    }
                }

                axios.post("/huawei/ont/nativeport/delete", data).then((result) => {
                    if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                    {
                        this.$store.dispatch('alert/error', result.data.Error, { root: true })
                        this.$store.dispatch('auth/logout')
                    }

                    this.ListNativePorts()
                });
            }
        }
    },
};
</script>
