<template>
<div class="col-xl-6">
    <div class="card mb-4">
        <div class="card-header">
            <i class="fas fa-chart-bar me-1"></i>
            WAN
            <div style="float: right">
                <div @click="ShowModalWanTemplate">
                    <i class="fas fa-plus"></i>
                </div>
            </div>
        </div>
        <div class="card-body">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Nazwa</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="Wan in WanList" v-bind:key="Wan.WanId">
                        <td @click="GetWanData(Wan)">Wan {{ Wan.WanId }}</td>
                        <td @click="DeleteWan(Wan)">X</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="col-xl-6">
    <div class="card mb-4">
        <div class="card-header">
            <i class="fas fa-chart-bar me-1"></i>
            Konfiguracja WLAN 
            <div style="float: right">
                <div @click="GenerateSsidAndPassword">
                    <i class="fas fa-sync"></i>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form @submit.prevent="AddWlan">
                <div class="form-floating mb-3">
                    <input class="form-control" id="nazwaWifi24Ghz" type="text" placeholder="Nazwa WIFI 2.4 GHZ" v-model="Ssid1Name" />
                    <label for="nazwaWifi24Ghz">Nazwa WIFI 2.4 GHZ</label>
                </div>
                <div class="form-floating mb-3">
                    <input class="form-control" id="nazwaWifi5Ghz" type="text" placeholder="Nazwa WIFI 5 GHZ" v-model="Ssid5Name" />
                    <label for="nazwaWifi5Ghz">Nazwa WIFI 5 GHZ</label>
                </div>
                <div class="form-floating mb-3">
                    <input class="form-control" id="haslo" type="text" placeholder="Hasło" v-model="WifiPassword" />
                    <label for="haslo">Hasło</label>
                </div>
                <div class="d-grid">
                    <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Zapisz</button>
                </div>
            </form>
        </div>
    </div>
</div>
<button class="btn btn-danger btn-lg" @click="SendConfig">Wyslij konfigurację</button>

<Modal v-show="IsModalWanVisible" @close="CloseModalWan">
<template v-slot:body>
    <form @submit.prevent="AddWan">
        <input type="hidden" v-model="WanEdit" />
        <div class="mb-3">
            <label class="form-label d-block">Encapsulation Mode</label>
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="iPoE" type="radio" name="encapsulationMode" value="0" v-model="WanEncapsulation" @change="EncapsulationMode" />
                <label class="form-check-label" for="iPoE">IPoE</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="PPPoE" type="radio" name="encapsulationMode" value="1" v-model="WanEncapsulation" @change="EncapsulationMode" checked/>
                <label class="form-check-label" for="PPPoE">PPPoE</label>
            </div>
        </div>
        <div class="form-floating mb-3">
            <select class="form-select" id="wanMode" aria-label="Wan Mode" v-model="WanMode" @change="SelectWanMode">
                <option value="0" :selected="WanEncapsulation == 1">Route Wan</option>
                <option value="1" :selected="WanEncapsulation == 0">Bridge Wan</option>
            </select>
            <label for="wanMode">Wan Mode</label>
        </div>
        <div class="form-floating mb-3">
            <select class="form-select" id="wanServiceType" aria-label="Service Type" v-model="WanServiceType">
                <option value="INTERNET" v-if="WanMode == 1" selected>INTERNET</option>
                <option value="IPTV" v-if="WanMode == 1">IPTV</option>
                <option value="TR069_VOIP_IPTV_INTERNET" v-if="WanMode == 0" selected>TR069_VOIP_IPTV_INTERNET</option>
                <option value="INTERNET" v-if="WanMode == 0">INTERNET</option>
            </select>
            <label for="wanServiceType">Service Type</label>
        </div>
        <div class="form-floating mb-3">
            <input class="form-control" id="vlanId" type="text" placeholder="VLAN ID" v-model="WanVlanId"/>
            <label for="vlanId">VLAN ID</label>
        </div>
        <div class="form-floating mb-3" v-if="WanShowAuth">
            <input class="form-control" id="username" type="text" placeholder="Username" v-model="WanUsername"/>
            <label for="username">Username</label>
        </div>
        <div class="form-floating mb-3" v-if="WanShowAuth">
            <input class="form-control" id="password" type="text" placeholder="Password" v-model="WanPassword"/>
            <label for="password">Password</label>
        </div>
        <div class="mb-3">
            <label class="form-label d-block">Binding Options</label>
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="lan1" type="checkbox" name="bindingOptions" v-model="BindingOptions" value="LAN1" checked/>
                <label class="form-check-label" for="lan1">LAN 1</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="lan2" type="checkbox" name="bindingOptions" v-model="BindingOptions" value="LAN2" checked/>
                <label class="form-check-label" for="lan2">LAN 2</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="lan3" type="checkbox" name="bindingOptions" v-model="BindingOptions" value="LAN3" checked/>
                <label class="form-check-label" for="lan3">LAN 3</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="lan4" type="checkbox" name="bindingOptions" v-model="BindingOptions" value="LAN4" checked/>
                <label class="form-check-label" for="lan4">LAN 4</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="ssid1" type="checkbox" name="bindingOptions" v-model="BindingOptions" value="SSID1" checked/>
                <label class="form-check-label" for="ssid1">SSID 1</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="ssid5" type="checkbox" name="bindingOptions" v-model="BindingOptions" value="SSID5" checked/>
                <label class="form-check-label" for="ssid5">SSID 5</label>
            </div>
        </div>
        <div class="form-floating mb-3">
            <input class="form-control" id="multicastVlanId" type="text" placeholder="Multicast VLAN ID" v-model="WanMulticastVlanId" />
            <label for="multicastVlanId">Multicast VLAN ID</label>
        </div>
                <div class="d-grid">
            <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Zapisz</button>
        </div>
    </form>
</template>
</Modal>

</template>

<script>
import Modal from '../components/Modal.vue'
import axios from 'axios'

export default {
    props: [ 'serialormac', 'ontid', 'oltid'],
    components: {
        Modal
    },
    data() {
        return {
            WanEncapsulation: 1,
            WanShowAuth: true,
            WanMode: 0,
            IsModalWanVisible: false,
            WanServiceType: "TR069_VOIP_IPTV_INTERNET",
            //wanEncapsulation: 1,
            //wanMode: 0,
            WanMulticastVlanId: null,
            WanVlanId: null,
            WanUsername: null,
            WanPassword: null,
            BindingOptions: [ "LAN1", "LAN2", "LAN3", "LAN4", "SSID1", "SSID5" ],
            //wanMulticastVlanId: null,
            WanBind: [],
            Check: true,
            Ssid1Name: null,
            Ssid5Name: null,
            WifiPassword: null,
            WanList: [],
            WanEdit: 0,
        }
    },
    created() {
        this.GetWlanData()
        this.ListWan();
    },
    methods: {
        EncapsulationMode() {
            if (this.WanEncapsulation == 0)
                this.WanShowAuth = false
            else
                this.WanShowAuth = true
        },
        SelectWanMode() {
            if (this.WanMode == 0)
                this.WanServiceType = "TR069_VOIP_IPTV_INTERNET"
            else
                this.WanServiceType = "INTERNET"
        },
        ShowModalWanTemplate() {
            this.IsModalWanVisible = true
        },
        CloseModalWan() {
            this.IsModalWanVisible = false
            this.WanEdit = 0
        },
        GetWanData(Wan)
        {
            this.WanEncapsulation = Wan.WanEncapsulation
            this.WanMode = Wan.WanMode
            this.WanVlanId = Wan.WanVlanId
            this.WanUsername = Wan.WanUsername
            this.WanPassword = Wan.WanPassword
            this.WanMulticastVlanId = Wan.WanMulticastVlanId
            this.BindingOptions = Wan.WanBindPorts
            this.IsModalWanVisible = true
            this.WanEdit = Wan.WanId
            this.WanServiceType = Wan.WanServiceType
        },
        ListWan() {
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiOntListWan: {
                    DbOntId: this.ontid
                }
            }

            axios.post("/huawei/ont/wan/list", data).then((result) => {
                if (result.data.Error == 401)
                    this.$store.dispatch('auth/logout')

                this.WanList = result.data.HuaweiOntListWan;
            })
        },
        AddWan() {
            const { WanEncapsulation, WanMode, WanServiceType, WanVlanId, WanUsername, WanPassword, BindingOptions, WanMulticastVlanId  } = this
            if (this.WanEdit == 0)
            {
                const data = {
                    SessionId: localStorage.getItem('user'),
                    HuaweiOntAddWan: {
                        OltId: this.oltid,
                        DbOntId: this.ontid,
                        WanEncapsulation: parseInt(WanEncapsulation),
                        WanMode: parseInt(WanMode),
                        WanServiceType: WanServiceType,
                        WanVlanId: parseInt(WanVlanId),
                        WanUsername: WanUsername,
                        WanPassword: WanPassword,
                        BindingOptions: BindingOptions,
                        WanMulticastVlanId: WanMulticastVlanId ? parseInt(WanMulticastVlanId) : 0
                    }
                }

                axios.post("/huawei/ont/wan/add", data).then((result) => {
                    if (result.data.Error == 401)
                        this.$store.dispatch('auth/logout')

                    this.IsModalWanVisible = false
                    this.ListWan()
                })
            }
            else
            {
                const data = {
                    SessionId: localStorage.getItem('user'),
                    HuaweiOntEditWan: {
                        OltId: this.oltid,
                        DbOntId: this.ontid,
                        WanId: this.WanEdit,
                        WanEncapsulation: parseInt(WanEncapsulation),
                        WanMode: parseInt(WanMode),
                        WanServiceType: WanServiceType,
                        WanVlanId: parseInt(WanVlanId),
                        WanUsername: WanUsername,
                        WanPassword: WanPassword,
                        BindingOptions: BindingOptions,
                        WanMulticastVlanId: WanMulticastVlanId ? parseInt(WanMulticastVlanId) : 0
                    }
                }

                axios.post("/huawei/ont/wan/edit", data).then((result) => {
                    if (result.data.Error == 401)
                        this.$store.dispatch('auth/logout')

                    this.WanEdit = 0
                    this.IsModalWanVisible = false
                    this.ListWan()
                })
            }
        },
        DeleteWan(Wan)
        {
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiOntDeleteWan: {
                    DbOntId: this.ontid,
                    WanId: Wan.WanId
                }
            }

            axios.post("/huawei/ont/wan/delete", data).then((result) => {
                if (result.data.Error == 401)
                    this.$store.dispatch('auth/logout')

                this.IsModalWanVisible = false
                this.ListWan()
            })
        },
        AddWlan() {
            const { Ssid1Name, Ssid5Name, WifiPassword } = this
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiOntWlanSave: {
                    OntId: this.ontid,
                    Ssid1: Ssid1Name,
                    Ssid5: Ssid5Name,
                    Password: WifiPassword
                }
            }

            axios.post("/huawei/ont/wlan/save", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }
            })
        },
        GetWlanData() {
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiOntWlanGet: {
                    OntId: this.ontid
                }
            }

            axios.post("/huawei/ont/wlan/get", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.Ssid1Name = result.data.HuaweiOntWlanGet.Ssid1
                this.Ssid5Name = result.data.HuaweiOntWlanGet.Ssid5
                this.WifiPassword = result.data.HuaweiOntWlanGet.Password
            })
        },
        GenerateSsidAndPassword()
        {
            let CharacterSet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
            let password = ''

            for(let i=0; i < 8; i++)
                password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))

            this.Ssid1Name = process.env.VUE_APP_ISP + "_" + this.serialormac.slice(-4)
            this.Ssid5Name = process.env.VUE_APP_ISP + "_" + this.serialormac.slice(-4) + "_5G"
            this.WifiPassword = password
        },
        SendConfig()
        {
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiOntRemoteConfig: {
                    DbOntId: this.ontid
                }
            }

            axios.post("/huawei/ont/remoteconfig", data).then((result) => {
                if (result.data.Error == 401)
                    this.$store.dispatch('auth/logout')
            })
        }
    }
};
</script>